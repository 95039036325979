import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Container from '../Container'
import styles from './NotFoundContent.module.css'
import imageSl from '../../images/not-found.svg'
import imageEn from '../../images/not-found-en.svg'

function NotFoundContent(props) {
  const strings = {
    sl: {
      title: 'Ojej, to pa je nerodno.',
      text:
        'Tvoja jeza je popolnoma upravičena, zato spodaj prilagamo obrazec, da lahko našemu programerju poveš, kar mu gre.',
      placeholder: 'Olajšaj si dušo tukaj...',
      button: 'OLAJŠAJ SI DUŠO',
      afterTitle: 'Nekaj je šlo narobe',
      afterText: 'Prosimo, osveži stran in poskusi ponovno.',
      refreshButton: 'Osveži stran'
    },
    en: {
      title: 'Upss, that is unfortunate.',
      text:
        'Your anger is completely justified. There is a form below where you can give our programmer a piece of your mind.',
      placeholder: 'Let your feelings go here ...',
      button: 'LET YOUR FEELINGS GO',
      afterTitle: 'Something went wrong.',
      afterText: 'Please refresh and try again.',
      refreshButton: 'Refresh page'
    }
  }
  const locales = strings[props.locale]

  const [posting, setPosting] = useState(false)
  const [answered, setAnswered] = useState(false)

  function onSubmit(e) {
    e.preventDefault()
    if (posting) return false
    setPosting(true)

    setTimeout(() => {
      setAnswered(true)
    }, 1200)
  }

  function refresh() {
    location.reload()
  }

  return (
    <Container>
      <div className={styles.content}>
        <div className={styles.image}>
          {props.locale === 'sl' && <img src={imageSl} alt="Stran ni bila najdena" />}
          {props.locale === 'en' && <img src={imageEn} alt="Page not found" />}
        </div>
        <h2 className={styles.title}>{answered ? locales.afterTitle : locales.title}</h2>
        <p className={styles.text}>{answered ? locales.afterText : locales.text}</p>
        {!answered && (
          <form
            action="./"
            validate="true"
            onSubmit={e => onSubmit(e)}
            className={`${posting ? styles.posting : ''}`}
          >
            <textarea
              placeholder={props.locale === 'sl' ? strings.sl.placeholder : strings.en.placeholder}
              required
            ></textarea>
            <div className={styles.submit}>
              <button type="submit" disabled={posting} className={styles.submitButton}>
                {locales.button} <img src={require('../../images/fwd.svg')} alt="" />
              </button>
            </div>
          </form>
        )}

        {answered && (
          <div className="refresh">
            <button className={styles.submitButton} onClick={refresh}>
              {locales.refreshButton} <img src={require('../../images/fwd.svg')} alt="" />
            </button>
          </div>
        )}
      </div>
    </Container>
  )
}

NotFoundContent.propTypes = {
  locale: PropTypes.string.isRequired
}

export default NotFoundContent
