import React, { useEffect, useContext } from 'react'
import SEO from '../components/seo'
import { GlobalDispatchContext, GlobalStateContext } from '../containers/GlobalContextProvider'
import NotFoundContent from '../components/404/NotFoundContent'

const NotFoundPage = props => {
  const dispatch = useContext(GlobalDispatchContext)
  const locale = props.pageContext.locale
  useEffect(() => {
    dispatch({
      type: 'SET_PAGE_CONTEXT_DATA',
      data: props.pageContext
    })
  }, [])

  return (
    <>
      <SEO title={locale == 'sl' ? 'Stran ni bila najdena' : 'Page not found'} />
      <NotFoundContent locale={locale}></NotFoundContent>
    </>
  )
}

export default NotFoundPage
